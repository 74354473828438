import { useEffect } from "react";
import { LanderConfigAction, useLanderConfig } from "../commons/ConfigContext";
import { BannerType } from "../banner/Banner";
import { experimentIds } from "../commons/experiment-helpers";
import { useAdsenseContext } from "../adsense/AdsenseContext";

export function useParking3696Experiment() {
  const { landerConfig, landerConfigDispatch } = useLanderConfig();
  const { hasAdsense } = useAdsenseContext();
  const { auctionsBannerExperimentId } = experimentIds;
  const { experiment } = landerConfig;

  const isExperiment =
    landerConfig?.lander?.banner?.type === BannerType.AUCTION &&
    experiment?.key === auctionsBannerExperimentId &&
    hasAdsense;

  useEffect(() => {
    if (!isExperiment) return;

    landerConfigDispatch({
      type: LanderConfigAction.UPDATE_BANNER_AND_CHANNEL_BY_TREATMENT,
      payload: experiment?.cohort === "pw_treatment",
    });
  }, [isExperiment, landerConfigDispatch, experiment?.cohort]);
}
