import React, { ReactElement } from "react";
import { getParkWebBannerText, isBannerEnabled } from "../../banner/Banner";
import TopBanner from "../../banner/TopBanner";
import { FooterParkWeb } from "../footer/FooterParkWeb";
import GetDomainButton from "../commonComponents/GetDomainButton";
import { TrustArcConsentDialog } from "../../consentManager/TrustArcComponents";
import { useConsentStatus } from "../../consentManager/useConsentStatus";
import { useLanderConfig } from "../../commons/ConfigContext";
import { AdContent } from "../AdContent";
import { LanderConfig } from "../../types/LanderConfig";
import classes from "./ParkWebCardLayout.module.scss";

type ParkWebLayoutProps = {
  page: string;
  content: { [key: string]: string };
};
export default function ParkWebLayout({ page, content }: ParkWebLayoutProps) {
  const { landerConfig } = useLanderConfig();
  const consentStatus = useConsentStatus();

  let domainContent: ReactElement | string = "";

  if (isBannerEnabled(landerConfig)) {
    domainContent = (
      <div className={ classes.contentLayout }>
        <div>
          <Domain landerConfig={ landerConfig } />
          <DomainInfo landerConfig={ landerConfig } content={ content } />
        </div>
        <div className={ classes.getDomain }>
          <GetDomainButton landerConfig={ landerConfig } />
        </div>
      </div>
    );
  }

  let adContent = null;
  if (consentStatus.showConsentDialog) {
    adContent = <TrustArcConsentDialog />;
  } else {
    adContent = (
      <div className={ classes.adContent }>
        <AdContent page={ page } />
      </div>
    );
  }

  return (
    <div className={ classes.parkWebLander }>
      <TopBanner darkMode />
      <div className={ classes.contentMain }>
        <div className={ classes.domainCard }>
          {domainContent}
          {adContent}
        </div>
      </div>

      <FooterParkWeb
        copyrightTextId="parkwebcopyrightText"
        parkwebDisclaimerText="parkwebDisclaimerText"
        darkMode
      />
    </div>
  );
}

type DomainInfoProps = {
  landerConfig: LanderConfig;
  content: { [key: string]: string };
};
export function DomainInfo({ landerConfig, content }: DomainInfoProps) {
  const text = getParkWebBannerText(landerConfig, content);
  if (typeof text === "undefined" && text === "") {
    return null;
  }
  return <div className={ classes.domainInfo }>{text}</div>;
}

type DomainProps = {
  landerConfig: LanderConfig;
};
export function Domain({ landerConfig }: DomainProps) {
  const domain = landerConfig.domain?.rootDomainDisplayText;
  if (!domain)
  if (typeof domain === "undefined" && domain === "") {
    return null;
  }
  return <div className={ classes.domain }>{domain}</div>;
}
