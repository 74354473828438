import React from "react";
import classNames from "classnames";
import { isMobileBrowser } from "../commons/HelperFunctions";
import TrustPilotWidget from "./TrustPilot/TrustPilotWidget";
import { GDLogo } from "./GDLogo";
import classes from "./TopBanner.module.scss";

const GD_LOGO_WIDTH = 150;

type TopBannerProps = {
  darkMode?: boolean;
};

function TopBanner({ darkMode }: TopBannerProps) {
  const isMobile = isMobileBrowser();

  return (
    <div id="topBanner" className={ classNames(classes.topBanner) }>
      <GDLogo
        width={ GD_LOGO_WIDTH }
        isMobile={ isMobile }
        color={ darkMode ? "white" : null }
      />
      <TrustPilotWidget isMobile={ isMobile } darkMode={ darkMode } />
    </div>
  );
}

export default TopBanner;
