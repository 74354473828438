import { useEffect } from "react";
import { LanderConfigAction, useLanderConfig } from "../commons/ConfigContext";
import { experimentIds } from "../commons/experiment-helpers";

// PARKING-3700 experiment
export function useParkWebCardDesign() {
  const { landerConfig, landerConfigDispatch } = useLanderConfig();
  const { parkWebRedesignExperimentId } = experimentIds;
  const { experiment } = landerConfig;

  const isExperiment = experiment?.key === parkWebRedesignExperimentId;

  useEffect(() => {
    if (!isExperiment) return;

    landerConfigDispatch({
      type: LanderConfigAction.UPDATE_BANNER_AND_CHANNEL_BY_TREATMENT,
      payload: {
        inTreatmentCohort: experiment?.cohort === "pw_treatment",
      },
    });
  }, [isExperiment, landerConfigDispatch, experiment?.cohort]);

  return isExperiment;
}
